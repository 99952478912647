import pickBy from "lodash/pickBy";
import {
  AggregateRating,
  BreadcrumbList,
  DayOfWeek,
  LocalBusiness,
  OpeningHoursSpecification,
  WithContext,
} from "schema-dts";

import { DISPENSARY_PLACEHOLDER_PHOTO } from "constants/dispensary";
import { RetailType } from "custom-types/Dispensary";
import { WeekDay, WeeklySchedule } from "custom-types/DispensarySchedules";
import { DispensaryReview } from "custom-types/Reviews";
import { getRetailerInfo } from "utils/getRetailerInfo";
import formatImgixUrl from "utils/image/formatImgixUrl";
import { retailCopyFormatter } from "utils/retailCopyFormatter";

const retailTypeSlug = (retailType: string, countryCode: string) => {
  switch (retailType) {
    case "clinic":
      return "doctor";
    case "cbd-store":
      return "cbd-store";
    default:
      return countryCode === "CA" ? "cannabis-store" : "dispensary-info";
  }
};

export const formatOpeningHoursSpecs = (
  schedules: WeeklySchedule,
): OpeningHoursSpecification[] => {
  const daysOfTheWeek: DayOfWeek[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    daysOfTheWeek
      // Pruning the days that have no open or close hours
      .filter((day) => {
        const storeHours = schedules[day.toString().toLowerCase() as WeekDay]
          ?.store || { close: { time: false }, open: { time: false } };

        return storeHours?.close.time || storeHours?.open.time;
      })
      .map((day): OpeningHoursSpecification => {
        const storeHours =
          schedules[day.toString().toLowerCase() as WeekDay].store;
        return {
          "@type": "OpeningHoursSpecification",
          closes: storeHours?.close.time + ":00" || "",
          dayOfWeek: day,
          opens: storeHours?.open.time + ":00" || "",
        };
      })
  );
};

const aggregateRatingJson = (
  roundedRating: string,
  reviewCount: number,
): AggregateRating | undefined => {
  if (!roundedRating && !reviewCount) {
    return;
  }

  return {
    "@type": "AggregateRating",
    ratingValue: roundedRating,
    reviewCount: reviewCount,
  };
};

const imageJson = (image: string | null | undefined) => {
  if (!image) {
    return DISPENSARY_PLACEHOLDER_PHOTO;
  }

  return image;
};

const buildUrl = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  dispensary: any,
  path: string,
  baseUrl: string,
  countryCode: string,
) => {
  const retailType = retailTypeSlug(dispensary.retailType, countryCode);
  let url = `${baseUrl}/${retailType}/${dispensary.slug}`;
  if (path) {
    url = `${url}${path}`;
  }
  return url;
};

export const breadcrumbJsonSchema = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  dispensary: any,
  geolocation: { city: string; state: string; slug: string },
  baseUrl: string,
  countryCode: string,
): WithContext<BreadcrumbList> => {
  if (!dispensary || !geolocation) {
    return { "@context": "https://schema.org", "@type": "BreadcrumbList" };
  }

  const { city, name, retailType, slug } = dispensary;
  const { city: geoCity, slug: geoSlug, state } = geolocation;

  const { url, finderUrl, copy } = getRetailerInfo(countryCode, retailType);
  const finderName = retailCopyFormatter(copy, "all");

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        item: baseUrl,
        name: "Home",
        position: 1,
      },
      {
        "@type": "ListItem",
        item: `${baseUrl}${finderUrl}`,
        name: finderName,
        position: 2,
      },
      {
        "@type": "ListItem",
        item: `${baseUrl}${finderUrl}/${geoSlug}`,
        name: state,
        position: 3,
      },
      {
        "@type": "ListItem",
        item: `${baseUrl}${finderUrl}/${geoSlug}/${geoCity}`,
        name: city,
        position: 4,
      },
      {
        "@type": "ListItem",
        item: `${baseUrl}${url}/${slug}`,
        name: name,
        position: 5,
      },
    ],
  };
};

export const dispensaryJsonSchema = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  currentDispensary: any,
  baseUrl: string,
  countryCode: string,
  reviews?: DispensaryReview[],
): WithContext<LocalBusiness> => {
  const scrubbedDispensaryData = pickBy(
    currentDispensary,
    (val) => val !== undefined && val !== null,
  );

  // Default values
  const dispensary = {
    acceptsDebitCards: false,
    address1: null,
    city: null,
    coverPhotoUrl: null,
    hasAtm: false,
    hasVeteranDiscount: false,
    isAdaAccessible: false,
    location: [
      {
        lat: null,
        lon: null,
      },
    ],
    name: null,
    phone: null,
    reviewCount: null,
    roundedRating: null,
    schedules: {},
    state: null,
    website: null,
    zip: null,
    ...scrubbedDispensaryData,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  } as any;
  return {
    "@context": "https://schema.org",
    "@id": buildUrl(dispensary, "", baseUrl, countryCode),
    "@type": "LocalBusiness",
    address: {
      "@type": "PostalAddress",
      addressLocality: dispensary.city,
      addressRegion: dispensary.state,
      postalCode: dispensary.zip,
      streetAddress: dispensary.address1,
    },
    aggregateRating: aggregateRatingJson(
      dispensary.roundedRating,
      dispensary.reviewCount,
    ),
    amenityFeature: [
      {
        "@type": "LocationFeatureSpecification",
        name: "ATM",
        value: dispensary.hasAtm,
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Veteran discount",
        value: dispensary.hasVeteranDiscount,
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "ADA accessible",
        value: dispensary.isAdaAccessible,
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Accepts debit cards",
        value: dispensary.acceptsDebitCards,
      },
    ],
    description: dispensary.description,
    email: dispensary.email,
    geo: {
      "@type": "GeoCoordinates",
      latitude: dispensary.locations?.[0].lat,
      longitude: dispensary.locations?.[0].lon,
    },
    image: imageJson(formatImgixUrl(dispensary.coverPhotoUrl)),
    logo: formatImgixUrl(dispensary.logoUrl),
    name: dispensary.name,
    openingHoursSpecification: formatOpeningHoursSpecs(dispensary.schedules),
    priceRange: "$$",
    telephone: dispensary.phone,
    url: buildUrl(dispensary, "", baseUrl, countryCode),
    ...(reviews &&
      reviews.length && { reviews: generateReviewSchemas(reviews) }),
  } as WithContext<LocalBusiness>;
};

const generateReviewSchemas = (reviews: DispensaryReview[] = []) => {
  return reviews.map((review) => ({
    "@type": "Review",
    author: {
      "@type": "Person",
      name: review.user.username,
    },
    datePublished: review.created,
    description: review.text,
    reviewRating: {
      "@type": "Rating",
      ratingValue: review.rating,
    },
  }));
};

type MetaData = {
  description: string;
  image: string;
  title: string;
};

export const retailerMainMetaData = (
  city: string,
  countryCode: string,
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
  retailType: RetailType,
  state: string,
): MetaData => {
  switch (retailType) {
    case RetailType.CbdStore:
      return cbdStoreMainMetaData(
        city,
        countryCode,
        coverPhotoUrl,
        logoUrl,
        name,
        state,
      );
    case RetailType.Clinic:
      return doctorsMetadata(coverPhotoUrl, logoUrl, name);
    default:
      return dispensaryMainMetaData(
        city,
        countryCode,
        coverPhotoUrl,
        logoUrl,
        name,
        state,
      );
  }
};

export const retailerMenuMetaData = (
  countryCode: string,
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
  retailType: RetailType,
): MetaData => {
  switch (retailType) {
    case RetailType.CbdStore:
      return cbdStoreMenuMetaData(countryCode, coverPhotoUrl, logoUrl, name);
    default:
      return dispensaryMenuMetaData(countryCode, coverPhotoUrl, logoUrl, name);
  }
};

export const dispensaryMainMetaData = (
  city: string,
  countryCode: string,
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
  state: string,
): MetaData => {
  return {
    description: `Explore the ${name} menu on Leafly. Find out what cannabis and CBD products are available, read reviews, and find just what you’re looking for.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title:
      countryCode !== "CA"
        ? `${name} | Dispensary Menu, Reviews & Photos`
        : `${name} | ${cityStateCombo(city, state)} Dispensary | Leafly`,
  };
};

export const dispensaryMenuMetaData = (
  countryCode: string,
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
): MetaData => {
  return {
    description: `Explore the ${name} menu on Leafly. Find out what cannabis and CBD products are available, read reviews, and find just what you’re looking for.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title:
      countryCode !== "CA"
        ? `${name} | Dispensary Menu, Reviews & Photos`
        : `${name} ${retailerCopy(countryCode, RetailType.Dispensary)} Menu | Leafly`,
  };
};

export const dealsMetadata = (
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
): MetaData => {
  return {
    description: `Find the best dispensary weed deals on flower, dabs, carts, and edibles happening today at ${name}.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title: `${name} Deals | Leafly`,
  };
};

export const reviewsMetadata = (
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
  pageNumber: number,
): MetaData => {
  const descriptionPageText = pageNumber > 1 ? ". Page " + pageNumber : "";
  const titlePageText = pageNumber > 1 ? " - Page " + pageNumber : "";
  return {
    description: `Read customer reviews for ${name} including scores for quality, service, and atmosphere. Been there? Leave a dispensary review of your own${descriptionPageText}.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title: `${name} Customer Reviews from Leafly${titlePageText}`,
  };
};

export const dispensaryMenuItemMetaData = (
  coverPhotoUrl: string,
  brandName: string,
  logoUrl: string,
  menuItemName: string,
  name: string,
): MetaData => {
  const brandClause = brandName ? ` by ${brandName}` : "";
  const brandNameClause = brandName ? ` ${brandName}` : "";

  return {
    description: `Shop at ${name} for ${menuItemName}${brandClause}. Browse the dispensary menu or find more${brandNameClause} products at Leafly.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title: `${menuItemName}${brandClause} at ${name}`,
  };
};

export const cbdStoreMainMetaData = (
  city: string,
  countryCode: string,
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
  state: string,
): MetaData => {
  return {
    description: `Explore the ${name} menu on Leafly. See what CBD, Delta 8, and Hemp CBD products are available, compare prices, or read product reviews.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title: `${name} | ${retailerCopy(countryCode, RetailType.CbdStore)} in ${cityStateCombo(city, state)} | Leafly`,
  };
};

export const cbdStoreMenuMetaData = (
  countryCode: string,
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
): MetaData => {
  return {
    description: `Explore the ${name} menu on Leafly. See what CBD, Delta 8, and Hemp CBD products are available, compare prices, or read product reviews.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title: `${name} Hemp ${retailerCopy(countryCode, RetailType.CbdStore)} Menu | Leafly`,
  };
};

export const doctorsMetadata = (
  coverPhotoUrl: string,
  logoUrl: string,
  name: string,
): MetaData => {
  return {
    description: `Get or renew your medical marijuana card at ${name}. Get business hours, find contact info, book an appointment, and get directions with Leafly.`,
    image: pageImage(coverPhotoUrl, logoUrl),
    title: `${name} | Medical Marijuana Cards`,
  };
};

const pageImage = (coverPhotoUrl: string, logoUrl: string): string => {
  return coverPhotoUrl || logoUrl;
};

const cityStateCombo = (city: string, state: string): string => {
  return state === "DC" && city.includes("DC") ? city : `${city}, ${state}`;
};

const retailerCopy = (countryCode: string, retailType: RetailType): string => {
  return retailCopyFormatter(
    getRetailerInfo(countryCode, retailType).copy,
    "capitalize",
  );
};

import {
  CBD_STORE_TABS,
  DISPENSARY_TABS_CA,
  DISPENSARY_TABS_US,
  DOCTOR_TABS,
} from "constants/dispensary";

class LinkMaker {
  countryCode: string;
  isUSA: boolean;
  retailType: string;
  slug: string;

  constructor({
    slug,
    countryCode,
    retailType,
  }: {
    slug: string;
    countryCode: string;
    retailType: string | undefined | null;
    useNewMenuLocation?: boolean;
  }) {
    this.slug = slug;
    this.countryCode = countryCode;
    this.isUSA = this.countryCode === "US";
    this.retailType = retailType || "dispensary";
  }

  getPagesFromCountryCode = () => {
    switch (this.retailType) {
      case "clinic":
        return DOCTOR_TABS;
      case "cbd-store":
        return CBD_STORE_TABS;
      case "dispensary":
        return this.isUSA ? DISPENSARY_TABS_US : DISPENSARY_TABS_CA;
      default:
        return this.isUSA ? DISPENSARY_TABS_US : DISPENSARY_TABS_CA;
    }
  };

  getAllTabsObjects = () => {
    if (!this.retailType) {
      return [];
    }
    const pageSettings = this.getPagesFromCountryCode();
    return Object.values(pageSettings).map(({ page, href }) => ({
      as: href.replace("[slug]", this.slug),
      href,
      page,
    }));
  };

  getRouteObject = (page: string) => {
    const pageSettings = this.getPagesFromCountryCode();
    const { href } = pageSettings[page] || pageSettings["dispensary"];
    return {
      as: href.replace("[slug]", this.slug),
      href,
    };
  };

  getMainTabRoute = () => {
    const pageSettings = this.getPagesFromCountryCode();
    const page = Object.keys(pageSettings)[0];
    return this.getRouteObject(page).as;
  };
}

export default LinkMaker;

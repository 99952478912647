import { useState } from "react";

import publicConfig from "config/public";
import { registerForPushNotifications } from "utils/pushNotifications";

import useDomainCountryCode from "./useDomainCountryCode";

export const usePushNotifications = () => {
  const [hasRegisteredForNotifications] = useState(
    typeof Notification !== "undefined" &&
      Notification.permission === "granted",
  );

  const countryCode = useDomainCountryCode();
  const userNotificationService =
    countryCode === "CA"
      ? publicConfig.services.userNotificationCaApi.url
      : publicConfig.services.userNotificationApi.url;

  return {
    hasRegisteredForNotifications,
    registerForPushNotifications: ({
      interests,
    }: { interests?: string[] } = {}) =>
      registerForPushNotifications(userNotificationService, { interests }),
  };
};

export default usePushNotifications;

import React, { useMemo } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getIsMenuProhibited } from "redux/selectors/complianceRules";
import LinkMaker from "utils/linkMaker";

import Tab from "components/botanic/Tab";
import Tabs from "components/botanic/Tabs";

const DispensaryTabs = ({
  activeTab,
  dispensary,
}: {
  activeTab?: string;
  dispensary: Dispensary;
}) => {
  const countryCode = useDomainCountryCode();
  const menuProhibited = useSelector(getIsMenuProhibited);

  const { asPath, query } = useRouter();

  const { retailType, slug, id, featureTier } = dispensary;

  const { publishEvent } = useEventTracker();

  const linkMaker = useMemo(
    () =>
      new LinkMaker({
        countryCode,
        retailType,
        slug: slug || "",
      }),
    [slug, countryCode, retailType],
  );

  const tabs = useMemo(() => {
    const tabs = linkMaker.getAllTabsObjects();

    if (menuProhibited && retailType === "dispensary") {
      return tabs.filter(
        ({ page }) => page === "main" || page === "reviews" || page === "deals",
      );
    } else if (featureTier === "info") {
      return tabs.filter(({ page }) => page === "main" || page === "reviews");
    } else {
      return tabs;
    }
  }, [retailType, featureTier, menuProhibited, linkMaker]);

  const trackClick = (tab: string) =>
    publishEvent({
      action: Action.click,
      category:
        retailType === "clinic"
          ? Category.doctorProfile
          : Category.dispensaryMenu,
      dispensaryId: id,
      label: `${tab} tab`.toLowerCase(),
      retailType,
    });

  return (
    <div className="text-sm" data-testid="app-tabs__container" id="top">
      <Tabs className="container" ariaLabel="Select a dispensary page">
        {tabs.map(({ page, as }) => {
          const active =
            (!query?.menuItem && asPath.split("?")[0] === as) ||
            (activeTab && activeTab === page);

          return (
            <Tab
              data-testid={`app-tabs__tab-${page}`}
              key={page}
              href={as}
              className={classnames("capitalize p-md", {
                "text-default border-none": !active,
                "text-green border-green": active,
              })}
              active={!!active}
              onClick={() => trackClick(page)}
            >
              {page}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default DispensaryTabs;

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "redux/reducers/rootReducer";

const getDispensary = (state: RootState) => state.dispensary;

export const getCurrentDeliveryZone = createSelector(
  [getDispensary],
  (dispensary) => {
    return dispensary.deliveryZone;
  },
);

export const getHasFollowed = createSelector(
  [getDispensary],
  (dispensary) => dispensary.followed,
);

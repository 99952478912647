import React, { ComponentProps } from "react";
import classNames from "classnames";
import Link from "next/link";

/**
 * Tabs may be used as sub-navigation within a site section
 * (e.g. dispensary pages) or within a section of a single page.
 * Tabs will generally be anchor tags (or Next's Link component)
 * when used as sub-navigation between pages.
 */
const Tab: React.FC<
  ComponentProps<typeof Link> & {
    active?: boolean;
  }
> = ({ active, className, ...others }) => {
  const classes = classNames(
    { "font-bold text-green border-b-2 border-green": active },
    "py-1.5 px-2 grow flex justify-center md:grow-0",
    className,
  );

  return <Link className={classes} {...others} />;
};

export default Tab;

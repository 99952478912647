import React from "react";

import TrackImpression from "components/TrackImpression";

// this component exists to allow New Frontier Data tracking pixels
// added through GTM to be targeted to certain states

type Props = {
  dispensaryState: string;
};
const TRACKED_DISPENSARIES = ["CO", "MI", "NJ", "MA", "WA", "CA", "IL", "AZ"];

const DispensaryPixelTracking: React.FC<Props> = ({ dispensaryState }) => {
  return TRACKED_DISPENSARIES.includes(dispensaryState) ? (
    <TrackImpression
      category="Dispensary State"
      label={dispensaryState}
      customDimensions={{ dispensaryState }}
    />
  ) : null;
};

export default DispensaryPixelTracking;

import React, { useState } from "react";
import classnames from "classnames";
import dynamic from "next/dynamic";

import { DISPENSARY_PLACEHOLDER_PHOTO } from "constants/dispensary";
import { Dispensary } from "custom-types/Dispensary";
import useWindowSize from "hooks/useWindowSize";
import { trackMenuEvent } from "utils/trackEvent";

import Image from "components/Image";

const LightBox = dynamic(() => import("components/LightBox"));

const PhotoGallery: React.FC<{
  className?: string;
  dispensary: Dispensary;
}> = ({ className, dispensary }) => {
  const { photos, name, coverPhotoUrl } = dispensary;

  const [showLightBox, setShowLightBox] = useState(false);

  const toggleLightBox = () => {
    if (photos?.length) {
      setShowLightBox(!showLightBox);
    }
    if (!showLightBox) {
      trackMenuEvent("Header", "Photo Opened");
    }
  };

  const lightBoxImages =
    photos?.map((photo: { imageUrl: string }) => ({
      imageUrl: photo.imageUrl,
    })) || [];

  const { isMobile } = useWindowSize();

  return (
    <>
      <div
        role="button"
        onClick={toggleLightBox}
        onKeyPress={toggleLightBox}
        tabIndex={0}
        className={classnames(
          "container rounded overflow-hidden dispensary-header__photo-gallery",
          className,
        )}
        data-testid="dispensary-header__photo-gallery"
        aria-label="toggle lightbox"
      >
        <Image
          src={coverPhotoUrl || DISPENSARY_PLACEHOLDER_PHOTO}
          sizes={[512, 736, null, 992, null, 736]}
          imgixParams={{
            fill: "blur",
            fit: "fill",
            h: isMobile ? "144" : "288",
          }}
          alt={name}
          className="dispensary-header__image"
          disableLazyLoad
        />
      </div>

      {showLightBox && (
        <LightBox
          images={lightBoxImages}
          onToggleLightboxClick={toggleLightBox}
        />
      )}

      <style jsx>{`
        .dispensary-header__photo-gallery :global(.dispensary-header__image) {
          height: 144px;
        }

        .dispensary-header__photo-gallery :global(img) {
          object-fit: cover;
        }

        @media screen and (max-width: 767px) {
          .dispensary-header__photo-gallery {
            margin-left: 0;
            margin-right: 0;
            border-radius: 0;
          }
        }

        @media screen and (min-width: 768px) {
          .dispensary-header__photo-gallery :global(.dispensary-header__image) {
            height: 288px;
          }
        }
      `}</style>
    </>
  );
};

export default PhotoGallery;

// Created this to prevent any oddities in the page query
// that had previously affected how google read and displayed
// some pages with pagination in search results.

export default (page: number | string | string[]): string => {
  let pageNumber = `${page}`;
  if (!Number(pageNumber)) {
    const pageRegex = pageNumber.match(/^[^\d]*(\d+)/g);
    if (pageRegex?.length && Number(pageRegex[0])) {
      pageNumber = pageRegex[0];
    } else {
      pageNumber = "1";
    }
  }

  return pageNumber;
};

import CheckIcon from "components/Icons/check_mark.svg";

const DefaultPickupFulfillmentTreatment = () => (
  <div className="flex flex-row justify-center rounded py-sm text-xs bg-leafly-white">
    <div className="flex items-center">
      <CheckIcon height="16" width="16" className="mx-xs" />
      <strong>Pickup available</strong>&nbsp;
      <span className="leading-none after:content-['•']" />
      &nbsp;Free&nbsp;
      <span className="leading-none after:content-['•']" />
      &nbsp;No minimum
    </div>
  </div>
);

export default DefaultPickupFulfillmentTreatment;

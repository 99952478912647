export const RETAIL_TYPES = {
  "cbd-store": {
    US: {
      copy: "CBD store",
      finderUrl: "/cbd-stores",
      metaCopy: "Hemp CBD store",
      url: "/cbd-store",
    },
  },
  clinic: {
    CA: {
      copy: "Doctor",
      finderUrl: "/medical-marijuana-doctors",
      metaCopy: "Doctor",
      url: "/doctors",
    },
    US: {
      copy: "Doctor",
      finderUrl: "/medical-marijuana-doctors",
      metaCopy: "Doctor",
      url: "/doctors",
    },
  },
  dispensary: {
    CA: {
      copy: "cannabis store",
      finderUrl: "/dispensaries",
      metaCopy: "cannabis",
      url: "/cannabis-store",
    },
    US: {
      copy: "dispensary",
      finderUrl: "/dispensaries",
      metaCopy: "cannabis",
      url: "/dispensary-info",
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
} as any;

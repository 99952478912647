import React, { ReactElement } from "react";

import { useSsoUrls } from "hooks/useSsoUrls";

import { Modal } from "components/botanic/Modal";

type SsoBumpoutModalProps = {
  closeModal: () => void;
  icon?: string | ReactElement;
  subtitle?: string;
  title?: string;
  trackSignIn?: () => void;
  trackSignUp?: () => void;
};

const SsoBumpoutModal: React.FC<SsoBumpoutModalProps> = ({
  closeModal,
  icon,
  subtitle = "Sign up if you don’t have an account!",
  title = "You need to be logged in to continue.",
  trackSignIn,
  trackSignUp,
}) => {
  const { signInUrl, signUpUrl } = useSsoUrls();

  return (
    <Modal
      onDismissed={closeModal}
      width={560}
      aria-labelledby="sso-modal-title"
    >
      <div className="text-default text-center pt-xl md:p-lg">
        {icon && <div className="flex justify-center mb-lg"> {icon} </div>}
        <h3 className="text-md pb-md mb-md" id="sso-modal-title">
          {title}
        </h3>
        <p className="text-sm">{subtitle}</p>
        <div>
          <a
            className="button col-1/2 mx-lg max-w-none button-full w-auto px-xxl mt-xl md:mt-xxl"
            role="button"
            data-testid="sign-up-link"
            tabIndex={0}
            onClick={() => {
              trackSignUp && trackSignUp();
            }}
            href={signUpUrl}
          >
            create account
          </a>
          <a
            className="col-1/2 mx-lg max-w-none button-full w-auto px-xxl mt-xl md:mt-xxl underline font-bold"
            tabIndex={0}
            onClick={() => {
              trackSignIn && trackSignIn();
            }}
            href={signInUrl}
          >
            sign in
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default SsoBumpoutModal;

import React from "react";

import { useGetPickupReadinessTime } from "hooks/useGetPickupReadinessTime";

import StorefrontIcon from "components/Icons/storefront.svg";
import PickupReadinessSkeleton from "components/Shared/Skeletons/PickupReadinessSkeleton";

type Props = {
  dispensaryId: number;
  hideSkeleton?: boolean;
  renderDefaultTreatment?: () => React.ReactNode;
  renderExtraBadgeContent?: () => React.ReactNode;
};

const PickupReadinessInfo: React.FC<Props> = ({
  dispensaryId,
  hideSkeleton,
  renderDefaultTreatment,
  renderExtraBadgeContent,
}) => {
  const { hasPickupLabel, pickupReadinessErrors, pickupReadinessIsLoading } =
    useGetPickupReadinessTime(dispensaryId);

  return pickupReadinessIsLoading && !hideSkeleton ? (
    <PickupReadinessSkeleton />
  ) : pickupReadinessErrors || !hasPickupLabel ? (
    <>{renderDefaultTreatment && renderDefaultTreatment()}</>
  ) : (
    <div className={"flex bg-leafly-white rounded text-default py-sm text-xs"}>
      <div className="flex flex-grow items-center justify-center">
        <StorefrontIcon height="18" width="18" className="mr-md" />
        <span className="font-bold">Pickup ready in under 30 mins</span>
        {renderExtraBadgeContent && renderExtraBadgeContent()}
      </div>
    </div>
  );
};

export default PickupReadinessInfo;

import consumerApi from "api/services/consumer-api";
import consumerApiCa from "api/services/consumer-api-ca";
import logError from "utils/logError";

type IsFollowingResult = {
  isFollowing: boolean;
};

export const getIsFollowing = async ({
  countryCode,
  followeeType,
  followeeId,
}: {
  countryCode: string;
  followeeType: string;
  followeeId: number;
}): Promise<boolean> => {
  const api = countryCode === "CA" ? consumerApiCa : consumerApi;

  try {
    const { data } = await api.get<IsFollowingResult>(
      `/api/followers/v1/is_following?followeeType=${encodeURIComponent(
        followeeType,
      )}&followeeId=${encodeURIComponent(followeeId)}`,
    );

    return data?.isFollowing || false;
  } catch (e) {
    logError(e.message, {
      functionName: "getIsFollowing",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return false;
  }
};

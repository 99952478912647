import publicConfig from "config/public";

import useDomainCountryCode from "./useDomainCountryCode";

const { leaflyDomain, leaflyCaDomain } = publicConfig;

export const getDomainFromContext = (countryCode: string) =>
  countryCode === "CA" ? leaflyCaDomain : leaflyDomain;

const useDomain = () => getDomainFromContext(useDomainCountryCode());

export default useDomain;

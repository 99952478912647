import { useState } from "react";

import { Action, Category, Label } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";

import CheckIcon from "components/Icons/check_mark.svg";

const RequestOnlineOrderingTreatment: React.FC<{
  dispensaryId: Dispensary["id"];
}> = ({ dispensaryId }) => {
  const [orderingRequested, setOrderingRequested] = useState(false);
  const { publishEvent } = useEventTracker();

  return (
    <div className="flex flex-row justify-center rounded py-sm text-xs bg-leafly-white">
      <div className="flex items-center">
        {orderingRequested && (
          <CheckIcon height="16" width="16" className="mx-xs" />
        )}
        <button
          className="font-bold underline"
          onClick={() => {
            setOrderingRequested(true);
            publishEvent({
              action: Action.click,
              category: Category.dispensaryHeader,
              dispensaryId,
              label: Label.requestOnlineOrdering,
            });
          }}
          disabled={orderingRequested}
        >
          Request online ordering
        </button>
        &nbsp;
        <span className="leading-none after:content-['•']" />
        &nbsp;In-store purchasing only
      </div>
    </div>
  );
};

export default RequestOnlineOrderingTreatment;

import React, { ReactNode } from "react";
import classNames from "classnames";

type TabsProps = {
  ariaLabel?: string;
  children: ReactNode;
  className?: string;
};

const Tabs: React.FC<TabsProps> = ({
  ariaLabel,
  children,
  className,
  ...others
}) => (
  <nav
    className={classNames(
      "flex justify-between overflow-x-auto border-b border-light-grey md:justify-normal md:gap-10",
      className,
    )}
    aria-label={ariaLabel}
    {...others}
  >
    {children}
  </nav>
);

export default Tabs;

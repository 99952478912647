const scrollToElement = (elementId: string) => {
  const offset = parseFloat(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--header-height",
    ),
  );

  if (elementId[0] === "#") {
    elementId = elementId.slice(1);
  }

  const element = document.getElementById(elementId);

  if (element) {
    window.scrollTo({
      behavior: "smooth",
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  }
};

export default scrollToElement;

import React from "react";
import Link from "next/link";

import { UNCLAIMED_INFO_LISTING_DISCLAIMER } from "constants/disclaimers";
import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";

import Tooltip from "components/Tooltip";

const ClaimInfoListing: React.FC<{
  dispensaryId: Dispensary["id"];
  retailType: string;
}> = ({ dispensaryId, retailType }) => {
  const { publishEvent } = useEventTracker();
  const claimStoreLink = "https://success.leafly.com/claim-your-store";
  const locationCategory = retailType === "clinic" ? "clinic" : "store";

  return (
    <div
      className="font-bold text-xs flex items-center space-x-1"
      data-testid="claim-dispensary"
    >
      <Link
        href={claimStoreLink}
        className="underline"
        onClick={() =>
          publishEvent({
            action: Action.click,
            category: Category.dispensaryMenu,
            dispensaryId,
            label: "info tier dispensary - upgrade now",
          })
        }
      >
        claim your {locationCategory}
      </Link>
      <Tooltip
        placement="top-left"
        tooltipId="show-leafly-list-tooltip"
        height={16}
        width={16}
        infoIconClass="text-grey"
        dialogClass="left-[-100px] md:left-[0px] top-[24px] w-[288px]"
        containerClass="flex items-center"
      >
        {UNCLAIMED_INFO_LISTING_DISCLAIMER}{" "}
        <Link href={claimStoreLink} className="underline">
          Claim your {locationCategory}.
        </Link>
      </Tooltip>
    </div>
  );
};

export default ClaimInfoListing;

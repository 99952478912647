/* eslint-disable no-empty -- TDOD: fix me, do not replicate */

import { useRouter } from "next/router";

import publicConfig from "config/public";
import sanitizePage from "utils/sanitizePage";

const { leaflyDomain, leaflyCaDomain } = publicConfig;

export default (omitPageQuery?: boolean): { caUrl: string; usUrl: string } => {
  const {
    asPath,
    query: { page, letter },
  } = useRouter();
  const basePath = asPath.split("?")[0];

  let usUrl;
  try {
    usUrl = new URL(basePath, leaflyDomain);
  } catch {}

  let caUrl;
  try {
    caUrl = new URL(basePath, leaflyCaDomain);
  } catch {}

  if (page) {
    const pageNumber = sanitizePage(page);

    if (Number(pageNumber) > 1 && !omitPageQuery) {
      usUrl && usUrl.searchParams.set("page", `${pageNumber}`);
      caUrl && caUrl.searchParams.set("page", `${pageNumber}`);
    }
  }

  // letter is used on cannabis glossary pages
  if (basePath === "/learn/cannabis-glossary") {
    usUrl && usUrl.searchParams.set("letter", `${letter || "a"}`);
    caUrl && caUrl.searchParams.set("letter", `${letter || "a"}`);
  }

  return { caUrl: `${caUrl}`, usUrl: `${usUrl}` };
};
/* eslint-enable no-empty -- TDOD: fix me, do not replicate */

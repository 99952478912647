import React from "react";
import Link from "next/link";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

import AwardBadgeIcon from "components/Icons/award_badge.svg";
import Tooltip from "components/Tooltip";

const LeaflyListBadge: React.FC<{
  dispensaryId: number;
}> = ({ dispensaryId }) => {
  return (
    <div className="flex items-center space-x-1">
      <span>
        <AwardBadgeIcon width={28} height={28} />
      </span>
      <span className="font-bold text-xs pb-[2px]">Leafly List Winner</span>
      <LeaflyListTooltip dispensaryId={dispensaryId} />
    </div>
  );
};
export const LeaflyListTooltip: React.FC<{
  dispensaryId: number;
}> = ({ dispensaryId }) => {
  const { publishEvent } = useEventTracker();

  const handleLearnMoreClick = () => {
    publishEvent({
      action: Action.click,
      category: Category.dispensaryHeader,
      dispensaryId: dispensaryId,
      label: "leafly list link",
    });
  };

  const handleTooltipClick = () => {
    publishEvent({
      action: Action.click,
      category: Category.dispensaryHeader,
      dispensaryId: dispensaryId,
      label: "leafly list tool tip",
    });
  };

  const tooltipText = (
    <span>
      Leafly Lists recognize and celebrate the best retailers serving
      top-quality cannabis with exceptional customer service in their community.
      Look for the iconic green ribbon throughout our platform that designates
      them as a Leafly List winner!{" "}
      <Link
        href="/news/leafly-list"
        onClick={handleLearnMoreClick}
        className="underline"
      >
        Learn more.
      </Link>
    </span>
  );

  return (
    <Tooltip
      placement="top-left"
      tooltipId="show-leafly-list-tooltip"
      height={16}
      width={16}
      infoIconClass="text-grey"
      dialogClass="left-[-100px] md:left-[0px] top-[24px] w-[288px]"
      onTooltipClick={handleTooltipClick}
    >
      {tooltipText}
    </Tooltip>
  );
};

export default LeaflyListBadge;

import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";

import { addFollower } from "api/requests/addFollower";
import { getIsFollowing } from "api/requests/getIsFollowing";
import { removeFollower } from "api/requests/removeFollower";
import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import usePushNotifications from "hooks/usePushNotifications";
import {
  DISPENSARY_FOLLOW,
  DISPENSARY_UNFOLLOW,
} from "redux/reducers/dispensary";
import { getHasFollowed } from "redux/selectors/dispensary";
import { getIsLoggedIn } from "redux/selectors/user";
import logError from "utils/logError";

import Button from "components/botanic/Button";
import FavoriteButton from "components/botanic/FavoriteButton";
import { Modal } from "components/botanic/Modal";
import SsoBumpoutModal from "components/SsoBumpoutModal";

const FollowDispensaryButton: React.FC<{
  buttonType?: "basic" | "favorite";
  className?: string;
  dispensaryId: Dispensary["id"];
  dispensaryName: Dispensary["name"];
}> = ({ buttonType = "favorite", className, dispensaryId, dispensaryName }) => {
  const { publishEvent } = useEventTracker();
  const [isSsoModalVisible, setIsSsoModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  const isFollowing = useSelector(getHasFollowed);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const countryCode = useDomainCountryCode();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFollowing) {
      getIsFollowing({
        countryCode,
        followeeId: dispensaryId,
        followeeType: "dispensary",
      }).then((isFollowing) => {
        if (isFollowing) {
          dispatch({
            type: DISPENSARY_FOLLOW,
          });
        }
      });
    }
  }, []);

  const { hasRegisteredForNotifications, registerForPushNotifications } =
    usePushNotifications();

  const toggleFollowAction = async () => {
    if (!dispensaryId) {
      return;
    }

    const eventLabel = isFollowing ? "unfollow button" : "follow button";

    publishEvent({
      action: Action.click,
      category: Category.dispensaryHeader,
      dispensaryId: dispensaryId,
      label: eventLabel,
    });

    if (isFollowing) {
      const removeFollowerSuccess = await removeFollower({
        countryCode,
        followeeId: dispensaryId,
        followeeType: "dispensary",
      });

      if (removeFollowerSuccess) {
        dispatch({
          type: DISPENSARY_UNFOLLOW,
        });
      }
    } else {
      const addFollowerSuccess = await addFollower({
        countryCode,
        followeeId: dispensaryId,
        followeeType: "dispensary",
      });

      if (addFollowerSuccess) {
        dispatch({
          type: DISPENSARY_FOLLOW,
        });
      }
    }

    return !isFollowing;
  };

  const onButtonClick = () => {
    if (isLoggedIn) {
      toggleFollowAction().then((isFollowing) => {
        if (isFollowing) {
          setIsConfirmationModalVisible(true);
        }
      });
    } else {
      setIsSsoModalVisible(true);
    }
  };

  const onContinueClick = () => {
    if (!hasRegisteredForNotifications) {
      registerForPushNotifications({
        interests: ["dispensary-updates"],
      })
        .then(() => {
          setIsConfirmationModalVisible(false);
        })
        .catch(() => {
          logError(
            "Error regiestering for push notifications in onContinueClick FollowDispensaryButton",
          );
          setIsConfirmationModalVisible(false);
        });
    } else {
      setIsConfirmationModalVisible(false);
    }
  };

  return (
    <>
      {buttonType === "favorite" ? (
        <FavoriteButton
          aria-label={`Follow ${dispensaryName || ""}`}
          data-testid="follow-button"
          onClick={onButtonClick}
          isFavorited={isFollowing}
          label={isFollowing ? "Favorited" : "Favorite"}
          className={className}
        />
      ) : (
        <Button
          variant="secondary"
          className={className}
          onClick={onButtonClick}
        >
          {isFollowing ? "favorited" : "favorite"}
        </Button>
      )}
      {isSsoModalVisible && (
        <SsoBumpoutModal
          closeModal={() => setIsSsoModalVisible(false)}
          trackSignUp={() => {
            setIsSsoModalVisible(false);
            publishEvent({
              action: Action.click,
              category: Category.dispensaryHeader,
              dispensaryId: dispensaryId,
              label: "sign-up from follow",
            });
          }}
        />
      )}
      {isConfirmationModalVisible && (
        <Modal
          onDismissed={() => {
            setIsConfirmationModalVisible(false);
          }}
          width={560}
          aria-labelledby="follow-button-modal-title"
        >
          <div className="text-center text-default pt-xl md:p-lg">
            <h3 className="text-md pb-md mb-md" id="follow-button-modal-title">
              {`You are now following ${dispensaryName}`}
            </h3>
            <p className="text-sm">
              {hasRegisteredForNotifications
                ? "We'll send you a notification when this dispensary has new deals."
                : "Enable browser notifications so you never miss a single deal from this dispensary."}{" "}
              You can also view them in your{" "}
              <Link
                href="/me/updates"
                className="font-bold underline text-green"
              >
                account
              </Link>
              .
            </p>
            <div>
              <Button
                tabIndex={0}
                onClick={onContinueClick}
                width="full"
                className="mx-lg px-xxl mt-xl md:mt-xxl"
              >
                {hasRegisteredForNotifications
                  ? "continue shopping"
                  : "enable browser notifications"}
              </Button>
              {!hasRegisteredForNotifications && (
                <Button
                  tabIndex={0}
                  variant="text"
                  width="full"
                  onClick={() => setIsConfirmationModalVisible(false)}
                  className="mx-lg px-xxl mt-xl md:mt-xxl"
                >
                  continue shopping
                </Button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FollowDispensaryButton;

/**
 * @name retailCopyFormatter
 * capitalizes and/or pluralizes copy
 * @param   {string} str  incoming copy value
 * @param   {string} formatType conditionally applies formatting based on the string value
 * @see formatType can have a value of "all" which applies all formatting
 * @see formatType can have a value of "capitalize" which capitalizes the first letter
 * @see formatType can have a value of "plural" which pluralizes the incoming copy
 * @returns {string} formatted copy
 */

export const retailCopyFormatter = (
  str: string,
  formatType?: string,
): string => {
  let copy = String(str || "");

  if (formatType === "all") {
    if (copy === "dispensary") {
      copy = "Dispensaries";
    } else if (copy) {
      copy = copy.charAt(0).toUpperCase() + copy.slice(1);
      copy += "s";
    }
  }

  if (formatType === "capitalize") {
    copy = copy.charAt(0).toUpperCase() + copy.slice(1);
  }

  if (formatType === "plural") {
    copy += "s";
  }

  return copy;
};

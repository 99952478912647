import axios from "axios";

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

type SubscriptionOptions = {
  /**
   * A list of topic subjects that the subscription should be created with.
   * If a list of interests is not supplied, the user will not receive any
   * push notifications; in this case interests will need to be added to the
   * subscription at a later point to enable push notifications. These subjects
   * must already be present in the topic configuration in the
   * user-notification-service: https://github.com/Leafly-com/user-notification-service/blob/main/src/config/topics.json
   */
  interests?: string[];
};

/**
 * This is an opt-in for users to get push notifications. At the time of this
 * writing, these messages are only sent when dispensaries publish "dispensary
 * updates". Potentially, we may use this to give other updates to the user
 * (order status, deals, etc.).
 */
export const registerForPushNotifications = async (
  userNotificationServiceUrl: string,
  { interests = [] }: SubscriptionOptions = {},
): Promise<boolean> => {
  const registration = await window.navigator.serviceWorker.register(
    "/notification-service-worker.js",
  );
  const permission = await window.Notification.requestPermission();

  if (permission === "granted") {
    const { publicKey } = (
      await axios({
        method: "GET",
        url: `${userNotificationServiceUrl}/v2/browser/key`,
        withCredentials: true,
      })
    ).data;

    const pushSubscription = await registration.pushManager.subscribe({
      applicationServerKey: urlBase64ToUint8Array(publicKey),
      userVisibleOnly: true,
    });

    if (pushSubscription) {
      // https://user-notification-service.leafly.com/swagger/#/push%20subscriptions/post_v2_user_browser_subscriptions
      await fetch(
        `${userNotificationServiceUrl}/v2/user/browser/subscriptions`,
        {
          body: JSON.stringify({ ...pushSubscription.toJSON(), interests }),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        },
      );

      return true;
    }
  }

  return false;
};

import React, { ReactNode, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

import InfoIcon from "components/Icons/info.svg";
import ClearIcon from "components/Icons/x.svg";

type Props = {
  containerClass?: string;
  dialogClass?: string;
  placement?: string;
  text?: ReactNode;
  tooltipId: string;
  children?: ReactNode;
  height?: number;
  width?: number;
  infoIconClass?: string;
  onTooltipClick?: () => void;
};

const Tooltip = ({
  containerClass = "",
  dialogClass = "",
  placement = "top-right",
  text,
  tooltipId,
  children,
  height = 24,
  width = 24,
  infoIconClass = "text-default",
  onTooltipClick,
}: Props) => {
  const [showDialog, setShowDialog] = useState(false);
  const tooltip = useRef<HTMLDivElement>(null);
  const button = useRef<HTMLButtonElement>(null);

  function toggleTooltip(el: Event) {
    const tooltipContains = tooltip?.current?.contains(el.target as Node);
    if (!tooltipContains && !button?.current?.contains(el.target as Node)) {
      setShowDialog(!!tooltipContains);
    }
  }

  useEventListener("click", toggleTooltip);

  return (
    <div className={`mt-xs relative ml-4 ${containerClass}`}>
      <button
        id={tooltipId}
        aria-label={`${showDialog ? "Hide" : "Show"} more information`}
        ref={button}
        data-testid="tooltip-button"
        onClick={() => {
          onTooltipClick && onTooltipClick();
          setShowDialog(!showDialog);
        }}
      >
        <InfoIcon height={height} width={width} className={infoIconClass} />
      </button>
      <div
        ref={tooltip}
        data-testid="tooltip"
        className={`absolute bg-white font-normal z-20 ml-xxl ${convertPlacementToTailwind(
          placement,
        )} ${dialogClass} shadow text-sm ${showDialog ? "" : "hidden"}`}
      >
        <div className="flex justify-end pr-sm pt-sm">
          <button
            onClick={(event) => {
              event.preventDefault();
              setShowDialog(!showDialog);
            }}
            aria-label="Hide this information box"
            data-testid="tooltip-close-button"
          >
            <ClearIcon height="20" width="20" />
          </button>
        </div>
        <div className="pb-lg pl-lg pr-lg text-sm">{text || children}</div>
      </div>
    </div>
  );
};

export default Tooltip;

function convertPlacementToTailwind(placement: string) {
  switch (placement) {
    case "top-left":
      return "top-0 left-0";
    case "bottom-left":
      return "bottom-0 left-0";
    case "bottom-right":
      return "bottom-0 right-0";
    default:
      return "top-0 right-0";
  }
}

import consumerApi from "api/services/consumer-api";
import consumerApiCa from "api/services/consumer-api-ca";
import logError from "utils/logError";

export type FolloweeType = "dispensary" | "strain" | "brand";

export const addFollower = async ({
  countryCode,
  followeeType,
  followeeId,
}: {
  countryCode: string;
  followeeType: FolloweeType;
  followeeId: number;
}): Promise<boolean> => {
  const api = countryCode === "CA" ? consumerApiCa : consumerApi;

  try {
    await api.post(
      `/api/followers/v1/`,
      {
        followeeId,
        followeeType,
      },
      {
        headers: {
          "x-country-code": countryCode,
        },
      },
    );

    return true;
  } catch (e) {
    // We don't need to log an error if the user is logged out
    if (e.statusCode !== 401) {
      logError(e.message, {
        functionName: "addFollower",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }
    return false;
  }
};

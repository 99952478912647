import React from "react";
import cx from "classnames";

import HeartFilledIcon from "components/Icons/heart_filled.svg";
import HeartOutlineIcon from "components/Icons/heart_outline.svg";

type Props = {
  /**
   * Provides additional classnames for the top-level `<button>` element
   */
  className?: string;
  /**
   * Determines the filled-in state of the button
   */
  isFavorited: boolean;
  /**
   * An optional label that appears next to the heart icon
   */
  label?: string | number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const FavoriteButton = ({
  className,
  isFavorited,
  label,
  ...buttonProps
}: Props) => {
  const ariaLabel =
    buttonProps["aria-label"] ||
    (isFavorited ? "Click to unfavorite" : "Click to favorite");

  return (
    <button
      aria-label={ariaLabel}
      aria-pressed={isFavorited}
      className={cx(
        "p-[6px] bg-white border border-light-grey flex items-center rounded-full min-w-fit",
        { "py-[6px] px-3": !!label },
        className,
      )}
      title={ariaLabel}
      {...buttonProps}
    >
      <div className="relative">
        <HeartOutlineIcon className="text-green" height="24" width="24" />
        <HeartFilledIcon
          className={cx(
            "absolute text-green top-0 left-0 transition-opacity duration-200",
            {
              "opacity-0": !isFavorited,
              "opacity-1": isFavorited,
            },
          )}
          height="24"
          width="24"
        />
      </div>

      {!!label && <span className="text-xs font-bold ml-xs">{label}</span>}
    </button>
  );
};
export default FavoriteButton;

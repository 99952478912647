import consumerApi from "api/services/consumer-api";
import consumerApiCa from "api/services/consumer-api-ca";
import logError from "utils/logError";

import { FolloweeType } from "./addFollower";

export const removeFollower = async ({
  countryCode,
  followeeType,
  followeeId,
}: {
  countryCode: string;
  followeeType: FolloweeType;
  followeeId: number;
}): Promise<boolean> => {
  const api = countryCode === "CA" ? consumerApiCa : consumerApi;

  try {
    await api.delete(
      `/api/followers/v1/?followeeType=${encodeURIComponent(
        followeeType,
      )}&followeeId=${followeeId}`,
      {
        headers: {
          "x-country-code": countryCode || "US",
        },
      },
    );
    return true;
  } catch (e) {
    // We don't need to log an error if the user is logged out
    if (e.statusCode !== 401) {
      logError(e.message, {
        functionName: "removeFollower",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }
    return false;
  }
};
